define('contentful-fragment/components/muuri-grid-component', ['exports', 'muuri'], function (exports, _muuri) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['muuri-grid-component'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.grid = new _muuri.default(this.element, {
        dragEnabled: true,
        dragSortInterval: 100,
        dragReleaseDuration: 300,
        dragReleaseEasing: 'ease',
        dragStartPredicate: {
          handle: '.cf-card-icon'
        }
      });
      this.grid.on('layoutEnd', function (items) {
        var newOrder = items.map(function (item) {
          return item.getElement().dataset.id;
        });
        return Ember.get(_this, 'updateSort')(newOrder);
      });
    },
    didUpdate: function didUpdate() {
      var grid = Ember.get(this, 'grid');
      var prevItems = Ember.get(this, 'grid._items');
      var prevItemIds = prevItems.map(function (item) {
        return item._element.getAttribute('data-id');
      });

      var currentItemIds = [];
      var currentGridElements = document.getElementsByClassName('muuri-item-component');

      for (var i = 0; i < currentGridElements.length; i++) {
        var itemEl = currentGridElements[i];
        var uuid = itemEl.getAttribute('data-id');

        if (!!prevItems[i] && itemEl.clientHeight !== prevItems[i]._height) {
          grid.refreshItems().layout();
        }

        if (!prevItemIds.includes(uuid)) {
          grid.add(itemEl);
        }

        currentItemIds.push(uuid);
      }

      prevItems.forEach(function (item) {
        var itemEl = item._element;
        if (!currentItemIds.includes(itemEl.getAttribute('data-id'))) {
          grid.remove(itemEl);
        }
      });

      grid.refreshItems();
    },
    willRemoveElement: function willRemoveElement() {
      delete this.grid;
    }
  });
});