define('contentful-fragment/helpers/is-image-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imageType = imageType;
  function imageType(params /*, hash*/) {
    var type = params[0] || "";
    return type.startsWith('image/');
  }

  exports.default = Ember.Helper.helper(imageType);
});