define('contentful-fragment/controllers/schema', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend({
    extension: Ember.inject.service(),

    deletingUUID: "",

    actions: {
      stageFieldDeletion: function stageFieldDeletion(field) {
        Ember.set(this, 'deletingUUID', field.uuid);
      },
      unstageFieldDeletion: function unstageFieldDeletion() {
        Ember.set(this, 'deletingUUID', "");
      },
      addEmptySchemaField: function addEmptySchemaField() {
        Ember.get(this, 'extension').addSchemaField();
      },
      keyDidChange: function keyDidChange() {
        Ember.get(this, 'extension').validateSchema();
      },
      setFieldType: function setFieldType(value) {
        var _value$split = value.split('-'),
            _value$split2 = _slicedToArray(_value$split, 2),
            uuid = _value$split2[0],
            type = _value$split2[1];

        var field = Ember.get(this, 'extension.data._schema').findBy('uuid', uuid);
        Ember.set(field, 'type', type);
        Ember.get(this, 'extension').validateSchema();
      },
      cancel: function cancel() {
        this.transitionToRoute('index');
      },
      save: function save() {
        var _this = this;

        Ember.get(this, 'extension').persist().then(function () {
          Ember.get(_this, 'extension').syncFragmentsToSchema();
          _this.transitionToRoute('index');
        });
      },
      removeFragmentField: function removeFragmentField(field) {
        Ember.get(this, 'extension').removeSchemaField(field);
      }
    }

  });
});