define('contentful-fragment/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    extension: Ember.inject.service(),
    fileQueue: Ember.inject.service(),

    editingUUID: "",
    showPreview: false,

    actions: {
      addFragment: function addFragment() {
        var newFragment = Ember.get(this, 'extension').addFragment();
        if (!newFragment) return;
        var uuid = Ember.get(newFragment.findBy('key', 'uuid'), 'value');
        // TODO: re-enable this after muuri rerender on "iEditing"
        // bug is fixed
        // set(this, 'editingUUID', uuid);
        return uuid;
      },
      editFragment: function editFragment(uuid) {
        Ember.set(this, 'editingUUID', uuid);
      },
      cancelEditing: function cancelEditing() {
        Ember.set(this, 'editingUUID', "");
      },
      removeFragment: function removeFragment(fragment) {
        Ember.get(this, 'extension').removeFragment(fragment);
        Ember.set(this, 'editingUUID', "");
      },


      // Savers
      save: function save() {
        Ember.get(this, 'extension').persist();
      },
      saveDate: function saveDate(fragmentField, date) {
        if (date) {
          Ember.set(fragmentField, 'value', date.toISOString());
          Ember.get(this, 'extension').persist();
        }
      },
      saveBlob: function saveBlob(fragmentField, file) {
        var _this = this;

        if (!file) return;

        var name = file.name,
            size = file.size,
            type = file.type;

        return file.readAsDataURL().then(function (data) {
          Ember.set(fragmentField, 'value', {
            data: data, name: name, size: size, type: type
          });
          return Ember.get(_this, 'extension').persist();
        }).finally(function () {
          var queue = Ember.get(_this, 'fileQueue.queues').find(function (queue) {
            return Ember.get(queue, 'files').includes(file);
          });
          if (queue) queue.remove(file);
        });
      },
      showPreview: function showPreview() {
        Ember.set(this, 'showPreview', true);
      },
      hidePreview: function hidePreview() {
        Ember.set(this, 'showPreview', false);
      },
      updateSort: function updateSort(order) {
        Ember.get(this, 'extension').updateSort(order);
      }
    }
  });
});