define('contentful-fragment/services/extension', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var TYPES = ['Symbol', 'Date', 'Blob'];

  var emptyForType = function emptyForType() /*type*/{
    // TODO
    return null;
  };

  var coerce = function coerce(type, value) {
    if (!value) return emptyForType(type);
    return value;
  };

  var generateUUID = function generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }
    var uuid = 'xxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
    return uuid;
  };

  var validateSchemaField = function validateSchemaField(field) {
    var validation = '';
    if (!field.key || field.key.length === 0) validation += 'Enter a key for this field';
    if (!field.type) {
      if (validation.length) validation += ', ';
      validation += 'Select a field type';
    }
    return validation;
  };

  var newFragmentFromSchema = function newFragmentFromSchema(schema) {
    return schema.reduce(function (acc, field) {
      return [].concat(_toConsumableArray(acc), [{
        key: field.key,
        value: emptyForType(field.type),
        type: field.type,
        _schemaRef: field.uuid
      }]);
    }, [{
      key: 'uuid',
      value: generateUUID()
    }]);
  };

  exports.default = Ember.Service.extend({
    data: null,
    extension: null,
    preview: null,

    setup: function setup(extension) {
      Ember.set(this, 'data', extension.field.getValue() || {});
      Ember.set(this, 'extension', extension);

      this.loadSchemaFromShorthand();
      this.syncFragmentsToSchema();
      this.makeSimpleFragments();
    },
    setSetting: function setSetting(key, value) {
      Ember.set(this, 'data._settings', Ember.get(this, 'data._settings') || {});
      Ember.set(this, 'data._settings.' + key, value);
    },
    loadSchemaFromShorthand: function loadSchemaFromShorthand() {
      var shorthand = Ember.get(this, 'extension.parameters.instance.schemaShorthand') || "";
      if (!shorthand.length) return;

      var parsedSchemaFields = shorthand.split(",").map(function (tuple) {
        return tuple.split(":").map(function (t) {
          return t.trim();
        });
      });
      var existingSchema = Ember.get(this, 'data._schema') || [];

      var loadedSchema = parsedSchemaFields.reduce(function (acc, fieldTuple) {
        var _fieldTuple = _slicedToArray(fieldTuple, 2),
            key = _fieldTuple[0],
            type = _fieldTuple[1];

        if (key.length === 0) {
          // eslint-disable-next-line
          console.warn('Contentful Fragment: Your Predefined Schema included a blank schema key! Please refer to the documentation.');
          return acc;
        }
        if (!TYPES.includes(type)) {
          // eslint-disable-next-line
          console.warn('Contentful Fragment: Your Predefined Schema included unknown type: ' + type + '. Must be one of <' + TYPES.join(' ') + '>');
          return acc;
        }
        var match = existingSchema.findBy('key', fieldTuple[0]);
        return [].concat(_toConsumableArray(acc), [{
          uuid: match ? match.uuid : generateUUID(), key: key, type: type
        }]);
      }, []).filter(function (schemaField) {
        return parsedSchemaFields.find(function (schemaTuple) {
          return schemaTuple[0] === schemaField.key;
        });
      });

      this.setSetting('usesPredefinedSchema', true);
      Ember.set(this, 'data._schema', loadedSchema);
      this.validateSchema();
    },


    /* Main Editor */
    addFragment: function addFragment() {
      if (Ember.get(this, 'data._schema')) {
        Ember.set(this, 'data.fragments', Ember.get(this, 'data.fragments') || []);
        var newFragment = newFragmentFromSchema(Ember.get(this, 'data._schema'));
        Ember.get(this, 'data.fragments').pushObject(newFragment);
        this.persist();
        return newFragment;
      }
    },
    removeFragment: function removeFragment(fragment) {
      if (Ember.get(this, 'data.fragments')) {
        var uuid = Ember.get(fragment.findBy('key', 'uuid'), 'value');
        var newFragments = this.data.fragments.reject(function (fragment) {
          return Ember.get(fragment.findBy('key', 'uuid'), 'value') === uuid;
        });
        Ember.set(this, 'data.fragments', newFragments);
        this.persist();
      }
    },


    /* Schema Editor */
    validateSchema: function validateSchema() {
      var allValid = Ember.get(this, 'data._schema').map(function (schemaField) {
        var validation = validateSchemaField(schemaField);
        Ember.set(schemaField, 'validation', validation);

        return validation.length === 0;
      }).every(Boolean);

      Ember.set(this, 'data.valid', allValid);
    },
    syncFragmentsToSchema: function syncFragmentsToSchema() {
      var _EmberGet = Ember.get(this, 'data'),
          fragments = _EmberGet.fragments,
          _EmberGet$_schema = _EmberGet._schema,
          _schema = _EmberGet$_schema === undefined ? [] : _EmberGet$_schema;

      var syncedFragments = (fragments || []).map(function (fragment) {
        var syncedFragment = _schema.map(function (schemaField) {
          var dataForSchemaField = fragment.findBy('_schemaRef', schemaField.uuid);
          return {
            key: schemaField.key,
            type: schemaField.type,
            value: coerce(schemaField.type, dataForSchemaField ? dataForSchemaField.value : null),
            _schemaRef: schemaField.uuid
          };
        });
        return [fragment.findBy('key', 'uuid')].concat(_toConsumableArray(syncedFragment));
      });

      Ember.set(this, 'data.fragments', syncedFragments);
      this.persist();
    },
    addSchemaField: function addSchemaField() {
      var newField = { key: '', type: null, uuid: generateUUID(), validation: '' };
      Ember.set(newField, 'validation', validateSchemaField(newField));
      if (Ember.get(this, 'data._schema')) {
        Ember.get(this, 'data._schema').pushObject(newField);
      } else {
        Ember.set(this, 'data._schema', [newField]);
      }

      return newField;
    },
    removeSchemaField: function removeSchemaField(field) {
      var newFields = Ember.get(this, 'data._schema').reject(function (existingField) {
        return existingField.uuid === field.uuid;
      });
      Ember.set(this, 'data._schema', newFields);
    },
    makeSimpleFragments: function makeSimpleFragments() {
      var simpleFragments = (Ember.get(this, 'data.fragments') || []).reduce(function (simpleFragments, fragment, index) {
        var uuid = Ember.get(fragment.findBy('key', 'uuid'), 'value');

        simpleFragments[uuid] = fragment.reduce(function (simpleFragment, fragmentField) {
          if (fragmentField.key === "uuid") return simpleFragment;
          if (fragmentField.key) {
            simpleFragment[fragmentField.key.camelize()] = fragmentField.value;
          }

          return _extends({
            index: index,
            uuid: uuid
          }, simpleFragment);
        }, {});

        return simpleFragments;
      }, {});

      Ember.set(this, 'data.simpleFragments', simpleFragments);
    },
    generateJSONPreview: function generateJSONPreview() {
      try {
        var jsonPreview = JSON.stringify(this.data, null, 2);
        Ember.set(this, 'preview', jsonPreview);
      } catch (e) {
        Ember.set(this, 'preview', 'Could not generate preview');
      }
    },
    updateSort: function updateSort(order) {
      var fragments = Ember.get(this, 'data.fragments');
      var resorted = order.map(function (uuid) {
        return fragments.find(function (fragment) {
          var fragmentUuid = Ember.get(fragment.findBy('key', 'uuid'), 'value');
          return uuid === fragmentUuid;
        });
      });

      Ember.set(this, 'data.fragments', resorted);
      this.persist();
    },
    persist: function persist() {
      this.makeSimpleFragments();
      this.generateJSONPreview();
      return this.extension.field.setValue(Ember.get(this, 'data'));
    }
  });
});