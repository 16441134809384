define('contentful-fragment/components/muuri-item-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['muuri-item-component', 'mb1'],
    classNameBindings: ['isEditing:muuri-item-component--editing'],
    attributeBindings: ['uuid:data-id'],
    modelId: Ember.computed('uuid', function () {
      return this.get('uuid');
    })
  });
});